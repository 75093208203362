@import '@/styles/variables';

.toggleInput {
  cursor: pointer;
  display: flex;
  gap: $spacing-3;
}

.checkbox,
.radio {
  --focus: 2px rgba(65, 112, 84, .3);
  --border-hover: #417054;
  --active: #417054;
  --border: #b0d3be;
  --disabled-inner: #c9d2cc;
  --disabled: #eef3f0;

  --active-inner: #fff;
  --background: #fff;

  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }

    & + span {
      cursor: not-allowed;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  &:not(.switch) {
    width: 21px;
    min-width: 21px;

    &:after {
      opacity: var(--o, 0);
    }

    &:checked {
      --o: 1;
    }
  }

  & + span {
    font-size: 16px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
  }
}

.checkbox {
  &:not(.switch) {
    border-radius: 7px;

    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 3px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }
  }

  &.switch {
    width: 38px;
    border-radius: 11px;

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: .6;
        }
      }
    }
  }
}

.radio {
  border-radius: 50%;

  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }

  &:checked {
    --s: .5;
  }
}

.checkboxButton {
  position: relative;

  &_input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:checked + .checkboxButton_label {
      background-color: $color-black;
      color: $font-color-light;
    }
  }

  &_label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-xs;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    padding: $spacing-4 $spacing-3;
    cursor: pointer;

    &:hover {
      border-color: $color-black;
    }
  }
}

.toggleInput__left {
  .toggleInput_child {
    order: 1;
  }

  .checkbox,
  .radio {
    order: 2;
  }
}
